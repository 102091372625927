*,
*:after,
*:before {
  margin: 0;
  box-sizing: border-box;
  /* background: url("assets/san-francisco-free-photo-picjumbo-com.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

html {
  background: url("assets/san-francisco-free-photo-picjumbo-com1.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.App {
  text-align: center;
  min-height: 100vh;
}
.body {
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  min-height: 100vh;
  height: 100%; 
  /* display: grid;
  align-items: center;
  justify-content: center; */
/*   
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}

/* ******** Navbar *********** */

nav {
  max-width: 100%;
  padding-left: 30px;
  padding-right: 10px;
  overflow: hidden;
  padding-top: 5px;
  padding-bottom: 10px;
  z-index: 1;
}

.navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}

.nav-links {
  display: flex;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  padding-right: 1px;
  font-size: .85em;
}

.nav-item a {
  display: inline-block;
  padding: 10px 15px;
  text-decoration: none;
  color: white;
}

.nav-item:hover a {
  color: rgb(107, 8, 8);
}



/* .header {
  background-color: #fff; */ 
  /* box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
  position: fixed;
  width: 100%;
  z-index: 3;
  color: white;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  /* background-color: #fff; */
/* } */

/* .header li a {
  display: block;
  padding: 20px 20px; */
  /* border-right: 1px solid #f4f4f4; */
  /* text-decoration: none;
  color: white;
} */

/* .header li a:hover,
.header .menu-btn:hover { */
  /* background-color: #f4f4f4; */
/* } */

/* menu */

/* .header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
} */

/* menu icon */

/* .header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: white;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: white;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
} */

/* menu btn */
/* 
.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
} */

/* 48em = 768px */

/* @media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header li a {
    padding: 20px 30px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
} */

@media only screen and (max-width: 1030px) {
  .nav-item:hover a {
    color: black;
    background-color: whitesmoke;
  }
}

@media only screen and (max-width : 375px) {
  .nav-links{
    font-size: .6em;
  }
}

@media only screen and (max-width: 410px) {
  nav {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 5px;
  }

  .nav-item a {
    padding: 10px 10px;
  }
}

/* ********* Home *********** */

.home-container {
  width: auto;
  height: auto;
  position: absolute;
  left: 25%;
  right: 25%;
  top: 35%;
  /* transform: translate(-50%, -50%); */
  border: solid 1px black;
  border-radius: 10px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
  z-index: 1;
	background: inherit;
  overflow: hidden;
  backdrop-filter: blur(30px);
}

.home-container:before {
	content: "";
	position: absolute;
	background: inherit;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
	filter: blur(10px);
	margin: -20px;
}

.home {
  margin: 30px;
}

.home h1 {
  font-family: "Courier";
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  width: auto;
  animation: type 4s steps(60, end);
}

.home h1:nth-child(2) {
  white-space:nowrap;
  overflow:hidden;    
  opacity:0;
  animation: type2 4s steps(60, end);
  animation-delay: 3.2s;
  animation-fill-mode: forwards;
}

.home h1:nth-child(3) {
  white-space:nowrap;
  overflow:hidden;    
  opacity:0;
  animation: type2 4s steps(60, end);
  animation-delay: 6s;
  animation-fill-mode: forwards;
}

@keyframes type{ 
  from { width: 0 } 
  to { width: 100% }
}

@keyframes type2{
  0%{width: 0;}
  to { width: 100% }
  from {opacity:0;}
  1%{opacity:1;}
  to{opacity:1;}
  100%{opacity:1;}
  }

.home-about-button {
  margin-top: 20px;
  width: 60%;
  padding: 5px 5px;
  border: solid 5px rgb(107, 8, 8);
  z-index: 1;
  background: inherit;
  overflow: hidden;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
  opacity: 0;
  white-space: nowrap;
  overflow: hidden;
  animation: type3 3s steps(60, end);
  animation-fill-mode: forwards;
  animation-delay: 10s;
  backdrop-filter: blur(30px);
}

.home-about-button:hover {
  background: #3570dc;
  border: solid 5px #3570dc;
}

.aboutbutton {
  font-family: "Courier";
  color: rgb(107, 8, 8);
  text-decoration: none;
  font-size: 2vw;
}

@keyframes type3 { 
  from {width: 60%;}
  to { width: 60% }
  from {opacity:0;}
  1%{opacity:1;}
  to{opacity:1;}
  100%{opacity:1;}
}

@media only screen and (max-width : 1184px) {
  .home-container {
    left: 10%;
    right: 10%;
  }
}

@media only screen and (max-width: 815px) and (min-width: 476px) {
  .home {
    font-size: .6em;
  }
}

@media only screen and (max-width : 475px) {
  .home {
    font-size: .4em;
  }

  .home-about-button {
    animation-delay: 10s;
  }

  .aboutbutton {
    font-size: .8em;
  }

  .home h1:nth-child(2) {
    animation-delay: 3.5s;
  }
}

@media only screen and (max-width : 325px) {
  .home {
    font-size: .35em;
  }

  .home-about-button {
    animation-delay: 11.5s;
  }

  .aboutbutton {
    font-size: .6em;
  }

  .home h1:nth-child(2) {
    animation-delay: 4s;
  }

  .home h1:nth-child(3) {
    animation-delay: 7s;
  }
}

/* ********* About ************** */

.about-container {
  width: auto;
  height: auto;
  text-align: left;
  position: absolute;
  left: 25%;
  right: 25%;
  top: 10%;
  /* transform: translate(-50%, -50%); */
  border: solid 1px black;
  border-radius: 10px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
  z-index: 1;
	background: inherit;
  overflow: hidden;
  font-family: "Courier";
  margin-top: 20px;
  margin-bottom: 20px;
  backdrop-filter: blur(30px);
}

.about-container:before {
	content: "";
	position: absolute;
	background: inherit;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
	filter: blur(10px);
	margin: -20px;
}

.about {
  margin: 30px;
}

.about h1 p{
  font-size: 1vw;
}

.about p {
  line-height: 1.4;
}

.about-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  padding: 5px 0;
}

.about-button {
  width: 25%;
  padding: 5px 0;
  border: solid 3px rgb(107, 8, 8);
  z-index: 1;
  background: inherit;
  overflow: hidden;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
  white-space: nowrap;
  margin: 0 20px;
  cursor: pointer;
}

.about-button:hover {
  background: #3570dc;
  border: #3570dc;
}

.experience-projects-button {
  font-family: "Courier";
  color: rgb(107, 8, 8);
  text-decoration: none;
  font-size: 15px;
}

@media only screen and (max-width : 1030px) {
  .about-container {
    left: 10%;
    right: 10%;
  }

  .about-button {
    width: 30%;
    margin: 0 10px;
  }
}

@media only screen and (max-width : 415px) {
  .about-container {
    left: 5%;
    right: 5%;
  }

  .about{
    font-size: .7em;
  }

  .about-button {
    width: 30%;
    margin: 0 10px;
  }

  .experience-projects-button {
    font-size: .9em;
  }
}

/* ******** Experience *********** */

.experience-container {
  width: auto;
  height: auto;
  text-align: left;
  position: absolute;
  left: 20%;
  right: 20%;
  top: 10%;
  /* transform: translate(-50%, -20%); */
  border: solid 1px black;
  border-radius: 10px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
  z-index: 1;
	background: inherit;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  backdrop-filter: blur(30px);
  font-family: "Courier";
}

.experience-container:before {
	content: "";
	position: absolute;
	background: inherit;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
	filter: blur(10px);
	margin: -20px;
}

.experience {
  margin: 30px;
  line-height: 1.4;
}

.key-skills {
  display: flex;
  flex-direction: row;
}

.key-skills-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.technical-skills {
  display: flex;
  flex-direction: row;
}

.technical-skills-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.resume-pdf-container {
  position: relative;
}

.resume-pdf {
  position: absolute;
  top: 0;
  right: 20px;
  text-decoration: none;
  color: black;
}

.experience-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding: 5px 0;
}

.experience-button {
  width: 17%;
  padding: 5px 0;
  border: solid 3px rgb(107, 8, 8);
  z-index: 1;
  background: inherit;
  overflow: hidden;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
  white-space: nowrap;
  margin: 0 20px;
  cursor: pointer;
}

.experience-button:hover {
  background: #3570dc;
  border: #3570dc;
}

.projects-contact-button {
  font-family: "Courier";
  color: rgb(107, 8, 8);
  text-decoration: none;
  font-size: 15px;
}

.expereince-fa {
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (max-width : 1030px) {
  .experience-container {
    left: 5%;
    right: 5%;
  }

  .experience-button {
    width: 30%;
    margin: 0 10px;
  }
}

@media only screen and (max-width : 415px) {
  .experience{
    font-size: .5em;
  }

  .key-skills-column ul {
    padding-inline-start: 10px;
  }

  .technical-skills-column ul {
    padding-inline-start: 10px;
  }

  .experience ul {
    padding-inline-start: 10px;
  }

  .experience-button {
    width: 30%;
    margin: 0 10px;
  }

  .projects-contact-button {
    font-size: .9em;
  }
}

/* ************ Projects *********** */

.project-header {
  margin: 30px auto 0;
  width: 25%;
  padding: 20px 0 20px 0;
  border: solid 1px black;
  border-radius: 10px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
  /* z-index: 1; */
	background: inherit;
  overflow: hidden;
  backdrop-filter: blur(30px);
  font-family: "Courier";
}

/* .project-header:before {
	content: "";
	position: absolute;
	background: inherit;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
	filter: blur(10px);
	margin: -4px;
} */

.projects-container {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 500px);
  /* grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); */
  grid-gap: 30px;
  margin: 50px 0;
}

.project-item {
  /* border: 1px solid rgba(0, 0, 0, 0.5); */
  /* padding: 10px; */
  /* box-shadow: 0px 1px 5px #555; */
  /* border-radius: 10px; */
  /* background-color: #f6f1e7; */
  position: relative;
  /* margin-top: 50px; */
}

.project-item-img {
  object-fit: fill;
  /* border: 1px solid rgba(0, 0, 0, 0.5); */
  width: 100%;
  height: 360px;
  /* height: 20rem; */
  /* max-height: 12em; */
  /* opacity: 1;
  transition: .5s ease;
  backface-visibility: hidden; */
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.project-item:hover .project-overlay {
  display: block;
  background: rgba(0, 0, 0, .5);
}

.project-title {
  position: absolute;
  width: 500px;
  left: 0;
  top: 120px;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  z-index: 1;
  transition: top .5s ease;
  opacity: -1;
}

.project-item:hover .project-title {
  top: 90px;
  opacity: 1;
}

.project-button {
  position: absolute;
  width: 500px;
  left:0;
  top: 180px;
  text-align: center;
  opacity: 0;
  transition: opacity .35s ease;
}

.project-button a {
  width: 100px;
  padding: 12px 48px;
  text-align: center;
  color: white;
  border: solid 2px white;
  margin: 5px;
  z-index: 1;
  text-decoration: none;
}

.project-item:hover .project-button {
  opacity: 1;
}

@media only screen and (max-width : 1030px) {
  .projects-container {
    grid-template-columns: repeat(auto-fit, 675px);
    /* margin: 10px; */
  }

  .project-item-img {
    object-fit: fill;
    width: 100%;
    /* height: 570px; */
  }
  .project-title {
    width: 675px;
    top: 190px;
    font-size: 48px;
  }
  .project-button {
    width: 675px;
    top: 280px;
  }
  .project-button a {
    padding: 20px 77px;
    font-size: 1.5em;
  }
  .project-header{
    width: 40%;
  }
}

@media only screen and (max-width: 825px) and (min-width: 565px) {
  .projects-container {
    grid-template-columns: repeat(auto-fit, 525px);
    /* margin: 10px; */
  }

  .project-item-img {
    object-fit: fill;
    width: 100%;
    /* height: 570px; */
  }
  .project-title {
    width: 525px;
    top: 125px;
    font-size: 32px;
  }
  .project-button {
    width: 525px;
    top: 184px;
  }
  .project-button a {
    padding: 13px 53px;
    font-size: 1.5em;
  }
  .project-header{
    width: 40%;
  }
}

@media only screen and (max-width : 415px) {
  .projects-container {
    grid-template-columns: repeat(1, 300px);
    /* grid-gap: 0px; */
  }

  .project-item-img {
    object-fit: fill;
    width: 100%;
    height: 215px;
  }
  .project-title {
    width: 300px;
    top: 72px;
    font-size: 20px;
  }
  .project-button {
    width: 300px;
    top: 130px;
  }

  .project-button a {
    padding: 7px 29px;
    font-size: .6em;
  }
  .project-item:hover .project-title {
    top: 54px;
  }
  .project-header{
    width: 70%;
    font-size: 1.5em;
    padding: 5px 0 5px 0;
  }

  /* .project-header:before {
    content: "";
    position: absolute;
    background: none;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
    filter: blur(0px);
    margin: 0;
  } */
}

/* ********** Contact Form *************** */

.contact-container {
  position: relative;
  width: 25%;
  height: auto;
  padding: 10px 25px;
  margin: 10vh auto;
  /* background: #a06d3e;
  border-radius: 8px; */
  border: solid 1px black;
  border-radius: 10px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
  z-index: 1;
	background: inherit;
  overflow: hidden;
  font-family: "Courier";
  backdrop-filter: blur(30px);
}

.contact-container:before {
	content: "";
	position: absolute;
	background: inherit;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
	filter: blur(10px);
	margin: -20px;
}

.contact-form {
  width: 100%;
  height: 100%;
}

.contact-form-label {
  text-align: left;
  display: block;
  margin-bottom: 2px;
  font-size: 20px;
}

.contact-header, .contact-name, .contact-email, .contact-message {
  text-align: left;
  display: block;
}

.contact-header {
  color: #242323;
  font-weight: bold;
  font-size: 35px;
  margin: 30px auto;
}

.contact-name, .contact-email, .contact-button {
  width: 100%;
  height: 45px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 15px;
  font-family: "Courier";
}

.contact-message {
  width: 100%;
  height: 100px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 10px;
  font-family: "Courier";
}

.contact-name, .contact-email, .contact-message {
  background: #ecf0f1; 
  border: solid 1px black;
  /* border-radius: 10px; */
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
}

.contact-button {
  color: #fff;
  cursor: pointer;
  /* margin-top: 10px; */
  background: rgb(107, 8, 8);
  transition: background 0.4s ease;
  width: max-content;
  padding: 10px;
}

.contact-button:hover {
  background: #3570dc;
}

.fa {
  padding: 5px;
  font-size: 20px;
  /* width: 50px; */
  text-align: center;
  text-decoration: none;
  margin: 5px 5px;
  border-radius: 5px;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-twitter {
  background: #55ACEE;
  color: white;
}

.fa-instagram {
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, 
  #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, 
  #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, 
  #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, 
  #f77638 70%, #fec66d 100%);
  color: white;
}

.fa-github {
  background: black;
  color: white;
}

@media only screen and (min-width : 280px) {
  .contact-container{ width: 90% }
}

@media only screen and (min-width : 480px) {
  .contact-container{ width: 60% }
}

@media only screen and (min-width : 768px) {
  .contact-container{ width: 40% }
}

@media only screen and (min-width : 992px) {
  .contact-container{ width: 30% }
}



/* *********** Extra *********** */

/* @media only screen and (max-width : 375px) {
  .logo{ 
    font-size: 1em;
  }
  .nav-links{
    font-size: .6em;
  }
  .search-button{
    width: 60px;
    font-size: 12px;
    height: 25px;
  }
} */



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
